//= require slick-carousel/slick/slick.js

$(function() {
  var conf = {
    // posts: {
    //   slidesToShow: 3,
    //   slidesToScroll: 1,
    //   responsive: [
    //     {
    //       breakpoint: 960, // jusqu'à 960
    //       settings: {
    //         slidesToShow: 3
    //       }
    //     },
    //     {
    //       breakpoint: 780, // jusqu'à 780
    //       settings: {
    //         slidesToShow: 2
    //       }
    //     },
    //     {
    //       breakpoint: 620, // jusqu'à 620
    //       settings: {
    //         slidesToShow: 1
    //       }
    //     }
    //     // You can unslick at a given breakpoint now by adding:
    //     // settings: "unslick"
    //     // instead of a settings object
    //   ]
    // }
    presentation: {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000
    },
    testimonies: {
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 779, // jusqu'à 780
          settings: {
            slidesToShow: 1
          }
        },
        {
          breakpoint: 959, // jusqu'à 780
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 1200, // jusqu'à 780
          settings: {
            slidesToShow: 1
          }
        }
      ]
    }
  };

  $('.c-slick').each(function() {
    // return;
    var $this = $(this);

    var component = $this.data('slick-component');
    var $component = $this.closest('.c-' + component);
    var slick_class = 'c-' + component + '--slick';
    if ($component.hasClass(slick_class)) return;
    $component.addClass(slick_class);

    $this.slick($.extend({
      dots: false,
      infinite: false,
      speed: 300,
      prevArrow: $this.prev(),
      nextArrow: $this.next(),
    }, conf[component]));
  })
});
