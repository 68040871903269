(function($) {
  $(function() {
    var $contacts = $('.js-contacts')

    $contacts.find('a').on('click', function(e) {
      if ($contacts.css('position') != 'absolute') return;

      if ($contacts.hasClass('c-banner__contacts--opened')) {
        e.stopPropagation();
      }
      else {
        e.preventDefault();
      }
    });

    $contacts.on('click', function() {
      if ($contacts.css('position') != 'absolute') return;

      $contacts.toggleClass('c-banner__contacts--opened');
    });

    $document.on('click', function(e) {
      if (!$contacts.hasClass('c-banner__contacts--opened')) return;

      if ($(e.target).closest('.js-contacts').length == 0) {
        $contacts.trigger('click');
      }
    });
  });
})(jQuery);
